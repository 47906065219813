




























































































































































































import Vue from 'vue';
import EmptyHint from '@/components/workspace/EmptyHint.vue';
import Auth from '@/libs/auth';
import FolderList from '@/components/workspace/FolderList.vue';
import WorkspaceObjectApi from '@/apis/WorkspaceObjectApi';
import { WorkspaceFolderModel, WorkspaceObjectModel } from '@/apis/models/WorkspaceObjectModel';
import WorkspaceHandler from '@/handlers/WorkspaceHandler';
import Breadcrumb from '@/components/workspace/Breadcrumb.vue';
import WorkspaceObjectItem from '@/components/test_maker/WorkspaceObjectItem.vue';
import MovePositionModal from '@/components/workspace/MovePositionModal.vue';
import UpdateWorkspaceObjectNameModal from '@/components/member_group_maker/UpdateWorkspaceObjectNameModal.vue';

enum WorkspaceObjectType {
  Folder = 'WorkspaceFolder',
  QuizGroup = 'WorkspaceQuizGroup',
  MemberGroup = 'WorkspaceMemberGroup',
  QuizEvent = 'WorkspaceQuizEvent',
  SoftLink = 'WorkspaceSoftLink'
}

export default Vue.extend({
  components: {
    EmptyHint,
    FolderList,
    Breadcrumb,
    WorkspaceObjectItem,
    MovePositionModal,
    UpdateWorkspaceObjectNameModal,
  },
  data() {
    return {
      selection: [] as WorkspaceObjectModel[],
      selectModeOpen: false,
      searchString: '',
    };
  },
  computed: {
    isLoading(): boolean {
      return this.$store.state.isLoading;
    },
    currentFolder(): WorkspaceFolderModel | null {
      return this.$store.state.currentFolder;
    },
    cardDisplayMode(): string { // 'list-mode', 'pic-mode'
      return this.$store.state.cardDisplayMode;
    },
    subfolders(): WorkspaceFolderModel[] {
      if (this.currentFolder) {
        const folderArr = this.currentFolder.children.filter((object) => object.metadata.type === WorkspaceObjectType.Folder);
        const softLinkFolderArr = this.currentFolder.children.filter((object) => {
          const isSoftLinkFolder = object.metadata.type === WorkspaceObjectType.SoftLink
                                  && object.sourceObject
                                  && object.sourceObject.metadata.type === WorkspaceObjectType.Folder;
          return isSoftLinkFolder;
        });

        // nature language sort.
        // eg: ['1', '10', '2'] --sort--> ['1', '2', '10']
        const collator = new Intl.Collator('en', { numeric: true, sensitivity: 'base' });
        if (softLinkFolderArr.length > 0) {
          return softLinkFolderArr.sort((a, b) => collator.compare(a.sourceObject.metadata.name.toUpperCase().trim(), b.sourceObject.metadata.name.toUpperCase().trim())) as WorkspaceFolderModel[];
        }
        return folderArr.sort((a, b) => collator.compare(a.metadata.name.toUpperCase().trim(), b.metadata.name.toUpperCase().trim())) as WorkspaceFolderModel[];
      }
      return [];
    },
    workspaceObjects(): WorkspaceObjectModel[] {
      if (this.currentFolder) {
        const fileArr = this.currentFolder.children.filter((object) => object.metadata.type !== WorkspaceObjectType.Folder && object.metadata.type !== WorkspaceObjectType.SoftLink);
        const softLinkFileArr = this.currentFolder.children.filter((object) => {
          const isSoftLinkFile = object.metadata.type === WorkspaceObjectType.SoftLink
                                && object.sourceObject
                                && object.sourceObject.metadata.type !== WorkspaceObjectType.Folder;
          return isSoftLinkFile;
        });

        // nature language sort.
        // eg: ['1', '10', '2'] --sort--> ['1', '2', '10']
        const collator = new Intl.Collator('en', { numeric: true, sensitivity: 'base' });
        if (softLinkFileArr.length > 0) {
          return softLinkFileArr.sort((a, b) => collator.compare(a.sourceObject.metadata.name.toUpperCase().trim(), b.sourceObject.metadata.name.toUpperCase().trim()));
        }
        return fileArr.sort((a, b) => collator.compare(a.metadata.name.toUpperCase().trim(), b.metadata.name.toUpperCase().trim()));
      }
      return [];
    },
    trimSearchString(): string {
      // 修掉輸入的空白
      const string = this.searchString.trim();

      return string;
    },
    searchList(): WorkspaceObjectModel[] {
      const result = [] as WorkspaceObjectModel[];
      this.workspaceObjects.forEach((element) => {
        let listName = '';
        if (element.metadata.type !== WorkspaceObjectType.MemberGroup) {
          listName = element.metadata.name;
        }
        if (element.metadata.type === WorkspaceObjectType.MemberGroup) {
          listName = element.uniqueName;
        }
        const inputName = this.trimSearchString;
        const indexOf = listName.toUpperCase().indexOf(inputName.toUpperCase());
        if (indexOf >= 0) {
          result.push(element);
        }
      });
      return result;
    },
    filteredMethodList(): WorkspaceObjectModel[] {
      if (this.trimSearchString !== '') {
        return this.searchList;
      }
      return this.workspaceObjects;
    },
  },
  watch: {
    '$route.params.folderId': {
      handler(folderId) {
        if (folderId) {
          WorkspaceHandler.loadFolder(folderId);
          this.selectModeOpen = false;
          this.selection = [];
        }
      },
      immediate: true,
    },
  },
  async mounted() {
    Auth.loginCheck();
  },
  methods: {
    put() {
      console.log(this.workspaceObjects);
    },
    reload() {
      if (this.currentFolder) {
        // reload folder
        WorkspaceHandler.loadFolder(this.currentFolder.uuid);
      }
      this.selectInit();
    },
    selectInit() {
      this.selectModeOpen = false;
      this.selection = [];
    },
    toggleSelectMode() {
      this.selectModeOpen = !this.selectModeOpen;
      if (!this.selectModeOpen) {
        this.selection = [];
      }
    },
    toggleCardDisplayMode() {
      if (this.cardDisplayMode === 'list-mode') {
        this.$store.commit('changeCardDisplayMode', 'pic-mode');
      } else {
        this.$store.commit('changeCardDisplayMode', 'list-mode');
      }
    },
    async toggleSelection(object: WorkspaceObjectModel | WorkspaceFolderModel) {
      const index = this.selection.findIndex((item) => item.uuid === object.uuid);

      if (index === -1) {
        this.selection.push(object);
      } else {
        this.selection.splice(index, 1);
      }
    },
    async moveCards() {
      this.$modal.show('move-position-modal', {
        currentFolder: this.currentFolder,
      });
    },
    async moveCardsSubmit(selectedFolderId: string) {
      console.log(selectedFolderId);
      this.$store.commit('updateLoading', true);

      const request = [] as Promise<void>[];
      // this.selection.forEach((object: WorkspaceObjectModel) => {
      //   if (this.currentFolder) {
      //     request.push(WorkspaceObjectApi.move(object.uuid, selectedFolderId));
      //   }
      // });

      for (let i = 0; i < this.selection.length; i += 1) {
        // eslint-disable-next-line no-await-in-loop
        await WorkspaceObjectApi.move(this.selection[i].uuid, selectedFolderId);
        console.log(`(${i + 1}/${this.selection.length})`);
      }

      await Promise.all(request);
      // reload folder
      this.visitFolder(selectedFolderId);
      this.$modal.hide('move-position-modal');
      this.selectInit();
      this.$store.commit('updateLoading', false);
    },
    removeCards() {
      this.$modal.show('dialog', {
        title: this.$t('確定刪除嗎?'),
        text: this.$t('刪掉就不會再回來囉'),
        buttons: [
          {
            title: this.$t('取消'),
            handler: () => {
              this.$modal.hide('dialog');
            },
          },
          {
            title: this.$t('確定'),
            handler: () => {
              this.$modal.hide('dialog');
              this.removeManyGroups();
            },
          },
        ],
      });
    },
    shareCards() {
      this.$modal.show('manage-share-mapping-modal', {
        sourceObjectId: this.selection[0].uuid,
      });
      this.selectInit();
    },
    enterGroupPreview() { // enent另外做在workspaceForEvents
      this.$store.commit('updateEditPermission', true);
      const object = this.selection[0];
      if (object.metadata.type === 'WorkspaceQuizGroup') {
        this.$store.commit('changeQuizGroup', object);
        this.$router.push('/manager/quizgroup-preview');
      } else if (object.metadata.type === 'WorkspaceMemberGroup') {
        this.$store.commit('changeMemberGroup', object);
        this.$router.push('/manager/membergroup-preview');
      }
    },
    enterGroup() { // enent另外做在workspaceForEvents
      this.$store.commit('updateEditPermission', true);
      const object = this.selection[0];
      if (object.metadata.type === 'WorkspaceQuizGroup') {
        this.$store.commit('changeQuizGroup', object);
        this.$router.push('/manager/quizgroup-maker');
      } else if (object.metadata.type === 'WorkspaceMemberGroup') {
        this.$store.commit('changeMemberGroup', object);
        this.$router.push('/manager/membergroup-maker');
      }
    },
    async removeManyGroups() {
      this.$store.commit('updateLoading', true);
      const request = [] as Promise<void>[];
      // this.selection.forEach((object: WorkspaceObjectModel) => {
      //   request.push(this.removeGroupCard(object));
      // });
      for (let i = 0; i < this.selection.length; i += 1) {
        // eslint-disable-next-line no-await-in-loop
        await WorkspaceObjectApi.remove(this.selection[i].uuid);
        console.log(`(${i + 1}/${this.selection.length})`);
      }
      await Promise.all(request);
      this.selectInit();
      if (this.currentFolder) {
        // reload folder
        WorkspaceHandler.loadFolder(this.currentFolder.uuid);
      }
      this.$store.commit('updateLoading', false);
    },
    async removeGroupCard(object: WorkspaceObjectModel) {
      await WorkspaceObjectApi.remove(object.uuid);

      if (this.currentFolder) {
        // reload folder
        WorkspaceHandler.loadFolder(this.currentFolder.uuid);
      }
    },
    async copyGroupCard(object: WorkspaceObjectModel) {
      // only support QuizGroup copy for now (v0.4.0)
      const copiedObject = await WorkspaceObjectApi.copyQuizGroup(object.uuid);
      if (this.currentFolder) { this.currentFolder.children.push(copiedObject); }
    },
    copyMemberGroupCard(object: WorkspaceObjectModel) {
      this.$modal.show('copy-memberGroup-modal', {
        workspaceObject: object,
      });
    },
    visitFolder(folderId: string) {
      const currentFolderId = this.$store.state.currentFolder.uuid;
      if (folderId !== currentFolderId) {
        // change params.folderId to switch folder
        this.$router.push({
          params: {
            folderId,
          },
        });
      }
    },
  },
});
